var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-form"
  }, [_c('div', {
    staticClass: "auth-form__content"
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm('form');
      }
    }
  }, [_c('div', {
    staticClass: "auth-form__remark"
  }, [_vm._v(" Данная форма регистрации предназначена только для собственников компаний для мониторинга эффективности своего персонала. Если вы являетесь сотрудником компании, которая использует данную систему для работы - обратитесь к своему руководителю для предоставления вам персонального доступа в приложение. ")]), _c('el-form-item', {
    attrs: {
      "label": "Имя и фамилия",
      "prop": "name"
    }
  }, [_c('el-input', {
    ref: "name",
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Компания",
      "prop": "company"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.company,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "company", $$v);
      },
      expression: "form.company"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "E-mail",
      "prop": "email"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "email"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Введите пароль",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Повторите пароль",
      "prop": "password_confirmation"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.password_confirmation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password_confirmation", $$v);
      },
      expression: "form.password_confirmation"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "size": "large"
    }
  }, [_c('el-checkbox', {
    model: {
      value: _vm.agree,
      callback: function callback($$v) {
        _vm.agree = $$v;
      },
      expression: "agree"
    }
  }, [_vm._v(" Я согласен(-на) на обработку персональных данных ")])], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "submit",
      "disabled": !_vm.agree
    }
  }, [_vm._v(" Зарегистрироваться "), _c('i', {
    staticClass: "el-icon-arrow-right el-icon-right"
  })])], 1)], 1)], 1), _c('div', {
    staticClass: "auth-form__footer"
  }, [_c('div', {
    staticClass: "auth-form__footer-row"
  }, [_vm._v(" Уже есть аккаунт? "), _c('router-link', {
    staticClass: "auth-form__link",
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" Войти ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }