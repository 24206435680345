<template>
  <div class="auth-form">
    <div class="auth-form__content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        @submit.native.prevent="submitForm('form')"
      >
        <div class="auth-form__remark">
          Данная форма регистрации предназначена только для собственников
          компаний для мониторинга эффективности своего персонала. Если вы
          являетесь сотрудником компании, которая использует данную систему для
          работы - обратитесь к своему руководителю для предоставления вам
          персонального доступа в приложение.
        </div>
        <el-form-item label="Имя и фамилия" prop="name">
          <el-input v-model="form.name" ref="name" type="text" />
        </el-form-item>
        <el-form-item label="Компания" prop="company">
          <el-input v-model="form.company" type="text" />
        </el-form-item>
        <el-form-item label="E-mail" prop="email">
          <el-input v-model="form.email" type="email" />
        </el-form-item>
        <el-form-item label="Введите пароль" prop="password">
          <el-input v-model="form.password" type="password" show-password />
        </el-form-item>
        <el-form-item label="Повторите пароль" prop="password_confirmation">
          <el-input
            v-model="form.password_confirmation"
            type="password"
            show-password
          />
        </el-form-item>
        <el-form-item size="large">
          <el-checkbox v-model="agree">
            Я согласен(-на) на обработку персональных данных
          </el-checkbox>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary el-button--stretch"
            native-type="submit"
            :disabled="!agree"
          >
            Зарегистрироваться <i class="el-icon-arrow-right el-icon-right"></i>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="auth-form__footer">
      <div class="auth-form__footer-row">
        Уже есть аккаунт?
        <router-link class="auth-form__link" :to="{ name: 'Login' }">
          Войти
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeRegistration",
  data() {
    const checkCompany = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.company) {
        callback(new Error(`${this.authErrors.company}`));
      } else {
        callback();
      }
    };
    const checkEmail = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.email) {
        callback(new Error(`${this.authErrors.email}`));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите новый пароль"));
      } else if (String(value).length < 6) {
        callback(new Error("Пароль должен быть не короче 6 символов"));
      } else if (/[а-я]|[А-Я]/gi.test(value)) {
        callback(new Error("Пароль не должен содержать кириллицу"));
      } else {
        if (this.form.password_confirmation !== "") {
          this.$refs.form.validateField("password_confirmation");
        }
        callback();
      }
    };
    const validatePassword2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль еще раз"));
      } else if (value !== this.form.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.password) {
        callback(new Error(`${this.authErrors.password}`));
      } else {
        callback();
      }
    };
    return {
      agree: false,
      form: {
        name: "",
        email: "",
        company: "",
        password: "",
        password_confirmation: "",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите имя и фамилию",
            trigger: ["blur", "change"]
          }
        ],
        company: [
          {
            required: true,
            message: "Введите название компании",
            trigger: ["blur", "change"]
          },
          { validator: checkCompany, trigger: "blur" }
        ],
        email: [
          {
            required: true,
            message: "Введите e-mail адрес",
            trigger: ["blur", "change"]
          },
          {
            type: "email",
            message: "Выбранное значение для e-mail адреса некорректно.",
            trigger: "blur"
          },
          { validator: checkEmail, trigger: "blur" }
        ],
        password: [
          { validator: validatePassword, trigger: "blur" },
          { validator: checkPassword, trigger: "blur" }
        ],
        password_confirmation: [
          { validator: validatePassword2, trigger: ["blur", "change"] }
        ]
      }
    };
  },
  mounted() {
    this.$refs.name.focus();
  },
  beforeDestroy() {
    this.$store.commit("Auth/setData", { label: "errors", data: null });
  },
  computed: {
    authErrors() {
      return this.$store.state.Auth.errors;
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$store.commit("Auth/setData", { label: "errors", data: null });
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.registration();
        } else {
          return false;
        }
      });
    },
    registration() {
      this.$store
        .dispatch("Auth/freeRegistration", this.form)
        .then(() => {
          this.$router.push({ name: "Login" });
        })
        .catch(err => {
          console.error(err.response);
          this.$refs["form"].validate(() => {});
        });
    }
  }
};
</script>
